
import { Component, Vue } from 'vue-property-decorator'
import { Page, Table } from '../../types/common'
import { PageType } from '../../types/version'
import { KeepAlive } from '@/utils/decorators'

@Component({
  name: 'VersionList'
})
@KeepAlive
export default class extends Vue {
  private agentList: Array<{ agentId: string; agentName: string }> = []
  private searchInfo = {
    versionName: '',
    agentId: ''
  }

  private tableData: Table = {
    loading: false,
    tr: [
      {
        label: '版本名称',
        prop: 'versionName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '版本描述',
        prop: 'versionDesc',
        minWidth: '180',
        showOverflowTooltip: true
      },
      {
        label: '所属代理商',
        prop: 'agentName',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '创建时间',
        prop: 'ctime',
        minWidth: '160',
        showOverflowTooltip: true
      }
    ],
    data: []
  }

  private total = 0
  private page = 1
  private size = 10
  created () {
    this.getAgentList()
    this.getData()
  }

  // 获取代理商列表
  getAgentList () {
    this.$axios.get(this.$apis.agent.selectAgentByList, {}).then((res) => {
      this.agentList = res.list || []
    })
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get<Page<PageType>>(this.$apis.version.versionPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    })
      .then((res) => {
        this.tableData.data = res.list || []
        this.total = res.total || 0
        this.tableData.loading = false
      })
      .catch((err) => {
        this.tableData.loading = false
        console.log(err)
      })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 刷新数据库
  onRefresh (id: string) {
    this.$axios.post(this.$apis.version.versionRefresh, {
      versionId: id
    })
      .then(() => {
        this.$message({
          message: '刷新成功',
          type: 'success'
        })
      })
  }

  onAdd () {
    this.$router.push({
      name: 'versionAdd'
    })
  }

  onUpdate (id: string) {
    this.$router.push({
      name: 'versionUpdate',
      params: { id }
    })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.version.versionDelete, {
          versionId: id
        })
          .then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.page = 1
            this.getData()
          })
          .catch((err) => {
            this.$message.error(err)
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
